import {lazy} from 'react';

// project import
import Loadable from 'components/loadable';
import DefaultLayout from 'components/default-page-layout';
import RoleHomePage from 'pages/consumer'
import {ProtectedRoute} from "./protected";

const Login = Loadable(lazy(() => import('pages/login')));

const MainRoutes = {
    path: '/',
    element: <DefaultLayout/>,
    children: [
        {
            path: '/',
            element: <ProtectedRoute><RoleHomePage/></ProtectedRoute>
        },
        {
            path: '/login',
            element: <Login/>
        },
    ]
};

export default MainRoutes;