import NetworkService from "./network.service";
import React from "react";
import {
    ActualEnum,
    FilterEnum,
    ForecastActionsEnum,
    HeatmapEnum,
    ModelEnum,
    RequestPandingModelActionsEnum,
    NotificationEnum,
    SummaryEnum,
    YoyEnum,
    AuthEnum
} from "constants/actions.enum";
import { ModelsType } from "context/app-reducers";
import { graphConfig } from "config/auth.config";
import { access } from "fs";

const BASE_URL = `https://${process.env.REACT_APP_API_HOST}`;

class ApiService {
    static _instance: ApiService;

    // eslint-disable-next-line no-useless-constructor
    constructor() {
    }

    static getInstance() {
        if (!ApiService._instance) {
            ApiService._instance = new ApiService();
        }
        return ApiService._instance;
    }

    async updateModelAsRecommended(dispatch: React.Dispatch<any>,
        filterActual: string, filterForecast: string, modelInst: ModelsType, reason: string, username: string) {
        const { model, forecast_date, source, category } = modelInst;
        try {
            let { data, errors } = await NetworkService.put(
                `${BASE_URL}/api/v1/forecast/model/${model}?source=${encodeURIComponent(source)}&category=${encodeURIComponent(category)}&forecast_date=${forecast_date}&change_reason=${reason}&username=${username}`,
                {});
            if (data) {
                dispatch({
                    type: NotificationEnum.SET_INFORMATION_NOTIFICATION
                    , payload: { message: 'The request on mark as recommended was sent' }
                });
                this.getForecastCategory(dispatch, category, filterActual, filterForecast);
            }

            if (errors) {
                dispatch({
                    type: NotificationEnum.SET_ERROR_NOTIFICATION,
                    payload: { message: `Something went wrong with next error text ${errors}` }
                });
            }
        } catch (errorMessage) {
            // @ts-ignore
            dispatch({ type: NotificationEnum.SET_ERROR_NOTIFICATION, payload: { message: errorMessage } });
        }
    }

    async updateRequestsApprove(dispatch: React.Dispatch<any>, model: string, source: string, category: string, forecastDate: string) {
        try {
            let { data, errors } = await NetworkService.put(
                `${BASE_URL}/api/v1/forecast/model/requests/approve?model=${encodeURIComponent(model)}&source=${encodeURIComponent(source)}&category=${encodeURIComponent(category)}&forecast_date=${forecastDate}`,
                {});

            if (data) {
                dispatch({
                    type: NotificationEnum.SET_SUCCESS_NOTIFICATION, payload: { message: `The model ${model} was successfully approved as new recommended` }
                });
            }

            if (errors && errors.length) {
                dispatch({ type: NotificationEnum.SET_ERROR_NOTIFICATION, payload: { message: errors.join(', ') } });

            }

            this.getPendingRequests(dispatch);
        }
        catch (errorMessage) {
            dispatch({ type: NotificationEnum.SET_ERROR_NOTIFICATION, payload: { message: errorMessage } });
        }
    }

    async updateRequestsDecline(dispatch: React.Dispatch<any>, model: string, source: string, category: string, forecastDate: string) {
        try {
            let { data, errors } = await NetworkService.put(
                `${BASE_URL}/api/v1/forecast/model/requests/decline?model=${encodeURIComponent(model)}&source=${encodeURIComponent(source)}&category=${encodeURIComponent(category)}&forecast_date=${forecastDate}`,
                {});

            if (data) {
                dispatch({
                    type: NotificationEnum.SET_INFORMATION_NOTIFICATION, payload: { message: `The model ${model} was declined` }
                });
            }

            if (errors && errors.length) {
                dispatch({ type: NotificationEnum.SET_ERROR_NOTIFICATION, payload: { message: errors.join(', ') } });
            }

            this.getPendingRequests(dispatch);
        }
        catch (errorMessage) {
            dispatch({ type: NotificationEnum.SET_ERROR_NOTIFICATION, payload: { message: errorMessage } });

        }
    }

    async updateCategoryForecastManualData(dispatch: React.Dispatch<any>, filterActuals: string, filterForecast: string, modelInst: ModelsType, newValue: {
        reason: string;
        year: number;
        value: number;
        quarter: number;
    }) {
        const { model, forecast_date, source, category } = modelInst;

        try {
            let response = await NetworkService.post(`${BASE_URL}/api/v1/forecast/category/${encodeURIComponent(category)}/model/${encodeURIComponent(model)}`, {
                forecast_date: forecast_date,
                fiscal_quarter: newValue.quarter,
                fiscal_year: newValue.year,
                new_value: newValue.value,
                change_reason: newValue.reason
            });

            if (response) {
                dispatch({
                    type: NotificationEnum.SET_SUCCESS_NOTIFICATION
                    , payload: { message: 'Data was successfully updated' }
                });
                this.getForecastCategory(dispatch, category, filterActuals, filterForecast);
            }

        } catch (errorMessage) {
            // @ts-ignore
            dispatch({ type: NotificationEnum.SET_ERROR_NOTIFICATION, payload: { message: errorMessage } });
        }
    }

    async getCategoriesList(dispatch: React.Dispatch<any>) {
        try {
            let { data, errors } = await NetworkService.get(`${BASE_URL}/api/v1/forecast/categories_list`);
            if (data) {
                dispatch({
                    type: FilterEnum.FETCH_CATEGORIES,
                    payload: {
                        categoriesList: data.categories,
                        actualsList: data.filters_actuals,
                        forecastList: data.filters_forecast
                    }
                });
            }
            if (errors) {
                dispatch({
                    type: NotificationEnum.SET_ERROR_NOTIFICATION,
                    payload: { message: `Something went wrong with next error text ${errors}` }
                });

            }
        } catch (errorMessage) {
            // @ts-ignore
            dispatch({ type: NotificationEnum.SET_ERROR_NOTIFICATION, payload: { message: errorMessage } });
        }
    }

    async getForecastCategory(dispatch: React.Dispatch<any>,
        category: string,
        actual: string,
        forecast: string,
        includeModels: boolean = true,
        includeSummary: boolean = true,
        includeHeatmap: boolean = true,
        includePreviousForecast: boolean = true
    ) {
        dispatch({
            type: NotificationEnum.START_LOADING_FORECAST
        });
        try {
            const { data, errors } = await NetworkService.get(
                `${BASE_URL}/api/v1/forecast/category/${encodeURIComponent(category)}?actuals_period=${actual}&forecast_period=${forecast}&include_models=${includeModels}&include_summary=${includeSummary}&include_heatmap=${includeHeatmap}&include_previous_forecast=${includePreviousForecast}
                `);

            if (data) {
                dispatch({
                    type: NotificationEnum.STOP_LOADING_FORECAST
                });
            }

            if (data.forecasts) {
                dispatch({
                    type: ForecastActionsEnum.FETCH_FORECAST,
                    payload: {
                        forecasts: data.forecasts,
                    }
                });
            }

            if (data.previous_forecast) {
                dispatch({
                    type: ForecastActionsEnum.FETCH_PREVIUS_FORECAST,
                    payload: {
                        previousForecast: data.previous_forecast,
                    }
                });
            } else {
                dispatch({
                    type: ForecastActionsEnum.FETCH_PREVIUS_FORECAST,
                    payload: {
                        previousForecast: {},
                    }
                });

                // TODO: uncomment it if will be required
                // dispatch({
                //     type: NotificationEnum.SET_INFORMATION_NOTIFICATION,
                //     payload: {
                //         message: `Previous forecast is absent for the selected category`
                //     }
                // });
            }

            if (data.actuals) {
                dispatch({
                    type: ActualEnum.FETCH_ACTUALS,
                    payload: {
                        actuals: data.actuals,
                    }
                });
            }
            if (data.yoy_changes) {
                dispatch({
                    type: YoyEnum.FETCH_YOY,
                    payload: {
                        yoy: data.yoy_changes,
                    }
                });
            }
            if (data.models) {
                dispatch({
                    type: ModelEnum.FETCH_MODELS,
                    payload: {
                        models: data.models,
                    }
                });
            }

            if (data.heatmap) {
                dispatch({
                    type: HeatmapEnum.FETCH_HEATMAP,
                    payload: {
                        heatmap: data.heatmap,
                    }
                });
            }

            if (data.filters_actuals && data.filters_actuals) {
                dispatch({
                    type: FilterEnum.FETCH_FILTERS,
                    payload: {
                        actualsList: data.filters_actuals,
                        forecastList: data.filters_forecast
                    }
                });
            } else {
                dispatch({
                    type: FilterEnum.FETCH_CATEGORIES_LIST_ERROR, payload: { error: 'Filters Not found' }
                })

                dispatch({
                    type: NotificationEnum.SET_ERROR_NOTIFICATION,
                    payload: {
                        message: `Filters wasn't updated`
                    }
                });
            }

        } catch (errorMessage) {
            // @ts-ignore
            dispatch({
                type: NotificationEnum.SET_ERROR_NOTIFICATION,
                payload: {
                    message: errorMessage
                }
            });
            dispatch({
                type: NotificationEnum.STOP_LOADING_FORECAST
            });
        }
    }

    async getCategoriesTotal(dispatch: React.Dispatch<any>, forecast: string) {
        try {
            const response = await NetworkService.get(
                `${BASE_URL}/api/v1/forecast/categories_total?forecast_period=${forecast}`);

            //
            if (response.data.clorox_summary && response.data.market_summary) {
                if (typeof response.data.clorox_summary === "object" && Array.isArray(response.data.clorox_summary)) {
                    dispatch({
                        type: SummaryEnum.FETCH_SUMMARY,
                        payload: {
                            summary: response.data,
                        }
                    });
                } else {
                    dispatch({
                        type: SummaryEnum.FETCH_SUMMARY,
                        payload: {
                            summary: { clorox_summary: [], market_summary: [] },
                        }
                    });
                }

            }

            if (response.errors) {
                dispatch({ type: NotificationEnum.SET_ERROR_NOTIFICATION, payload: { message: 'Something went wrong' } });
            }

        } catch (errorMessage) {
            // @ts-ignore
            dispatch({ type: NotificationEnum.SET_ERROR_NOTIFICATION, payload: { message: errorMessage } });
        }
    }
    async getForecastDownload(dispatch: React.Dispatch<any>, forecast: string = '', category: string = '', summaryView: string = '') {
        try {
            const response = await NetworkService.get(
                `${BASE_URL}/api/v1/forecast/download?forecast_period=${forecast}&category=${encodeURIComponent(category)}&summary_type=${summaryView}`);

            if (typeof response === 'string') {
                return response;
            } else {
                dispatch({ type: NotificationEnum.SET_ERROR_NOTIFICATION, payload: { message: 'Something went wrong' } });
            }
        } catch (errorMessage) {
            // @ts-ignore
            dispatch({ type: NotificationEnum.SET_ERROR_NOTIFICATION, payload: { message: errorMessage } });
        }
    }


    async getPendingRequests(dispatch: React.Dispatch<any>) {
        try {
            const response = await NetworkService.get(
                `${BASE_URL}/api/v1/forecast/model/requests`);

            if (response.data) {
                dispatch({
                    type: RequestPandingModelActionsEnum.FETCH_RECOMMENDED_REQUESTS,
                    payload: {
                        requests: response.data,
                    }
                });
            } else {
                dispatch({ type: NotificationEnum.SET_ERROR_NOTIFICATION, payload: { message: 'Something went wrong' } });
            }
        } catch (errorMessage) {
            // @ts-ignore
            dispatch({ type: NotificationEnum.SET_ERROR_NOTIFICATION, payload: { message: errorMessage } });
        }
    }


    async getForecastCategoryDownload(dispatch: React.Dispatch<any>, category: string, forecast: string, models: string, actuals: string) {
        try {
            const response = await NetworkService.get(
                `${BASE_URL}/api/v1/forecast/category/${encodeURIComponent(category)}/download?forecast_period=${forecast}&category=${encodeURIComponent(category)}&models=${models}&actuals_period=${actuals}`);

            if (typeof response === 'string') {
                return response;
            } else {
                dispatch({ type: NotificationEnum.SET_ERROR_NOTIFICATION, payload: { message: 'Something went wrong' } });
            }
        } catch (errorMessage) {
            // @ts-ignore
            dispatch({ type: NotificationEnum.SET_ERROR_NOTIFICATION, payload: { message: errorMessage } });
        }
    }


    async getUserInfo(dispatch: React.Dispatch<any>) {
        try {
            const graphResponse = await NetworkService.get(graphConfig.graphMeEndpoint, null, { useAccessToken: true });
            if (graphResponse) {
                dispatch({ type: AuthEnum.FETCH_USER_INFO, payload: { user: graphResponse } });
            } else {
                dispatch({ type: NotificationEnum.SET_ERROR_NOTIFICATION, payload: { message: 'Cannot fetch user data' } });

            }

        } catch (errorMessage) {
            // @ts-ignore
            dispatch({ type: NotificationEnum.SET_ERROR_NOTIFICATION, payload: { message: errorMessage } });
        }

    }

    async getUserRole(dispatch: React.Dispatch<any>) {
        try {
            const { data, errors } = await NetworkService.get(
                `${BASE_URL}/api/v1/profile/roles`, null, {useAccessToken: true}
            )
            
            if (data && data.length) {
                dispatch({ type: AuthEnum.FETCH_ROLE, payload: { role: data[0] } });
                    } else {
                dispatch({ type: NotificationEnum.SET_ERROR_NOTIFICATION, payload: { message: `User doesn't have access` } });
            }

        } catch (errorMessage) {
            // @ts-ignore
            dispatch({ type: NotificationEnum.SET_ERROR_NOTIFICATION, payload: { message: errorMessage } });
        }

    }

}


export default ApiService.getInstance();