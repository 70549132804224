import {
    ActualEnum,
    AuthEnum,
    FilterEnum,
    ForecastActionsEnum,
    HeatmapEnum,
    ModelEnum,
    NotificationEnum,
    SummaryEnum,
    RequestPandingModelActionsEnum,
    YoyEnum
} from "../constants/actions.enum";
import { AppInitialStateType } from "./app-context";
import { RolesEnum } from "../constants/roles.enum";
import { v4 as uuidv4 } from "uuid";
import { getRoleViews } from "config/roles.config";

type ActionMap<M extends {
    [index: string]: any
}> = {
        [Key in keyof M]: M[Key] extends undefined
        ? {
            type: Key;
        }
        : {
            type: Key;
            payload: M[Key];
        }
    };

export type FilterItemType = {
    id: number;
    name: string;
}

export type DataPointType = {
    "period": string;
    "fiscal_year": number;
    "fiscal_quarter": number;
    "value": number;
    "value_k": number;
}

export type YOYDataType = {
    "fiscal_year": number;
    "fiscal_quarter": number;
    "yoy_quarterly_change": number;
    "yoy_quarterly_change_overwrite": number | null;
    "change_reason"?: string | null;
    "changed_by"?: string | null;
    "changed_at"?: string | null;
}

export type SummaryYOYDataType = {
    "fiscal_year": number,
    "year_value"?: number,
    "year_value_overwrite"?: number,
    "yearly_avg": number,
    "yearly_avg_overwrite": number,
    "quarters": YOYDataType[]
}

export type YOYChangesType = {
    forecasts: YOYModelsType[];
    actuals: YOYDataType[];
}

export type YOYModelsType = {
    model: string;
    source: string;
    category: string;
    forecast_date: string;
    avg: YOYDataType[];
    low: YOYDataType[];
    high: YOYDataType[];
};
export type SummaryCategoryRow = {
    category: string;
    avg: SummaryYOYDataType[];
    low: SummaryYOYDataType[];
    high: SummaryYOYDataType[];
}

export type SummaryGroupRow = {
    group: string;
    categories: SummaryCategoryRow[];
    total_values: SummaryYOYDataType[];
}

export type SummaryTotalRow = {
    label: string;
    groups: SummaryGroupRow[];
    total_values: SummaryYOYDataType[];
}

export type SummaryCategoriesTotalData = {
    [SummaryEnum.MARKET]: SummaryTotalRow[];
    [SummaryEnum.CLOROX]: SummaryTotalRow[];
}

export type Notification = {
    message: string;
    messageId: string;
    type: string;
    isLoadingForecast: boolean;
    isLoadingSummary: boolean;
}

export type ValueSummaryCategoriesTotalData = keyof SummaryCategoriesTotalData;

export type PreviusForcastData = {
    metric: string;
    actuals: YOYDataType[];
} & YOYModelsType;

export type RequestPendingModel = {
    model: string;
    source: string;
    category: string;
    forecast_date: string;
    status: string;
    created_by: string;
    created_at: string;
    change_reason: string;
}

export type ModelsType = {
    model: string;
    source: string;
    category: string;
    short_description: string;
    full_description: string;
    training_start_date: string;
    training_end_date: string;
    validation_start_date: string;
    validation_end_date: string;
    mape_value: string;
    is_model_recommended_originally: boolean;
    is_model_recommended: boolean;
    has_pending_request: boolean;
    change_reason: string;
    window_length: string;
    forecast_horizon: string;
    forecast_date: string;
    checked?: boolean;
    isHighLowShowing?: boolean;
};

export type FiltersType = {
    categories: string[];
    actualsList: string[];
    forecastList: string[];
    selectedCategory: string;
    selectedActual: string;
    selectedForecast: string;
    selectedSummaryView: ValueSummaryCategoriesTotalData
    error: string;
}

export type AuthType = {
    role: string;
    view: number;
    user: UserInfoType
}

export type UserInfoType = {
    businessPhones: string;
    displayName: string | null;
    givenName: string | null;
    id: string;
    jobTitle: string | null;
    mail: string | null;
    mobilePhone: string | null;
    officeLocation: string | null;
    preferredLanguage: string | null;
    surname: string | null;
    userPrincipalName: string | null;
}

export type ForecastType = {
    category: string;
    source: string;
    model: string;
    forecast_date: string;
    metric: string;
    avg: DataPointType[];
    low: DataPointType[];
    high: DataPointType[];
}

export type HeatMapType = {
    category: string,
    source: string,
    variable_1: string,
    variable_2: string,
    correlation: number
}

type ModelPayload = {
    [ModelEnum.LOAD_MODELS]: {
        id: number;
        name: string;
        price: number;
    };
    [ModelEnum.FETCH_MODELS]: {
        models: ModelsType[]
    };
    [ModelEnum.SET_MARKED_MODEL]: {
        model: string;
    };
    [ModelEnum.SET_HIGH_LOW]: {
        model: string;
    };
};

type AuthPayload = {
    [AuthEnum.SET_ROLE]: {
        role: string;
    }
    [AuthEnum.SWITCH_ROLE]: {
        view: number;
    }
    [AuthEnum.FETCH_ROLE]: {
        role: string
    }
    [AuthEnum.FETCH_USER_INFO]: {
        user: UserInfoType
    }
};

type RecommendedPayload = {
    [RequestPandingModelActionsEnum.FETCH_RECOMMENDED_REQUESTS]: {
        requests: RequestPendingModel[];
    }
};

type FilterPayload = {
    [FilterEnum.SET_ACTUAL]: {
        name: string;
    };
    [FilterEnum.SET_CATEGORY]: {
        name: string;
    };
    [FilterEnum.SET_FORECAST]: {
        name: string;
    };
    [FilterEnum.SET_SUMMARY_VIEW]: {
        name: SummaryCategoriesTotalData;
    };
    [FilterEnum.FETCH_CATEGORIES]: {
        forecastList: string[];
        actualsList: string[];
        categoriesList: string[]
    };
    [FilterEnum.FETCH_FILTERS]: {
        forecastList: string[];
        actualsList: string[];
    };
    [FilterEnum.FETCH_CATEGORIES_LIST_ERROR]: {
        message: string;
    };
};

type ForecastPayload = {
    [ForecastActionsEnum.FETCH_FORECAST]: {
        forecasts: [];
    };
};

type ActualsPayload = {
    [ActualEnum.FETCH_ACTUALS]: {
        actuals: [];
    };
};

type YOYPayload = {
    [YoyEnum.FETCH_YOY]: {
        yoy: YOYChangesType;
    };
};

type HeatmapPayload = {
    [HeatmapEnum.FETCH_HEATMAP]: {
        heatmap: HeatMapType[];
    };
};

type SummaryPayload = {
    [SummaryEnum.FETCH_SUMMARY]: {
        summary: SummaryCategoriesTotalData;
    };
};

type PreviousForecastPayload = {
    [ForecastActionsEnum.FETCH_PREVIUS_FORECAST]: {
        previousForecast: PreviusForcastData;
    };
};

type NotificationPayload = {
    [NotificationEnum.SET_ERROR_NOTIFICATION]: {
        message: string;
    };
    [NotificationEnum.SET_SUCCESS_NOTIFICATION]: {
        message: string;
    };
    [NotificationEnum.SET_INFORMATION_NOTIFICATION]: {
        message: string;
    };
    [NotificationEnum.SET_WARNING_NOTIFICATION]: {
        message: string;
    };
    [NotificationEnum.START_LOADING_FORECAST]: {
    };
    [NotificationEnum.STOP_LOADING_FORECAST]: {
    };
    [NotificationEnum.START_LOADING_SUMMARY]: {
    };
    [NotificationEnum.STOP_LOADING_SUMMARY]: {
    };
};

export type FilterActions = ActionMap<FilterPayload>[keyof ActionMap<
    FilterPayload
>];

export type AuthActions = ActionMap<AuthPayload>[keyof ActionMap<
    AuthPayload
>];

export type ModelActions = ActionMap<ModelPayload>[keyof ActionMap<
    ModelPayload
>];
export type ForecastActions = ActionMap<ForecastPayload>[keyof ActionMap<
    ForecastPayload
>];

export type PreviosForecastActions = ActionMap<PreviousForecastPayload>[keyof ActionMap<
    PreviousForecastPayload
>];

export type ActualsActions = ActionMap<ActualsPayload>[keyof ActionMap<
    ActualsPayload
>];

export type YoyActions = ActionMap<YOYPayload>[keyof ActionMap<
    YOYPayload
>];

export type HeatmapActions = ActionMap<HeatmapPayload>[keyof ActionMap<
    HeatmapPayload
>];

export type SummaryActions = ActionMap<SummaryPayload>[keyof ActionMap<
    SummaryPayload
>];
export type NotificationActions = ActionMap<NotificationPayload>[keyof ActionMap<
    NotificationPayload
>];

export type RecommendedRequestsActions = ActionMap<RecommendedPayload>[keyof ActionMap<
    RecommendedPayload
>];

type CombinedType =
    ModelActions
    | FilterActions
    | ForecastActions
    | ActualsActions
    | YoyActions
    | HeatmapActions
    | AuthActions
    | SummaryActions
    | NotificationActions
    | RecommendedRequestsActions
    | PreviosForecastActions;

export const ModelReducer = (
    state: ModelsType[],
    action: CombinedType,
    gState: AppInitialStateType
): ModelsType[] => {
    switch (action.type) {
        case ModelEnum.FETCH_MODELS:
            const modelsWithSameCategory = state.length > 0 && action.payload.models.length > 0 && state[0].category === action.payload.models[0].category;

            if (modelsWithSameCategory) {
                return [
                    ...action.payload.models.map((modelIteam: ModelsType) => {
                        const equalModelIndex = state.findIndex((existingModel => existingModel.model === modelIteam.model));
                        const hasSameModel = equalModelIndex !== -1;

                        return {
                            ...modelIteam,
                            checked: (hasSameModel && state[equalModelIndex].checked) || (!hasSameModel && modelIteam.is_model_recommended),
                            isHighLowShowing: ((hasSameModel && state[equalModelIndex].isHighLowShowing) || (!hasSameModel && modelIteam.is_model_recommended))
                        }
                    })]
            } else {
                return [
                    ...action.payload.models.map((modelIteam: ModelsType) => {
                        return {
                            ...modelIteam,
                            checked: modelIteam.is_model_recommended,
                            isHighLowShowing: modelIteam.is_model_recommended
                        }
                    })]
            }
        case ModelEnum.SET_MARKED_MODEL:
            return [...state.map(model => {
                if (model.model === action.payload.model) {
                    model.checked = !model.checked
                } else {
                    model.isHighLowShowing = false;
                }
                return model;
            })];
        case ModelEnum.SET_HIGH_LOW:
            return [...state.map(model => {
                if (model.model === action.payload.model) {
                    model.isHighLowShowing = !model.isHighLowShowing
                    model.checked = true;
                } else {
                    model.checked = false;
                    model.isHighLowShowing = false;
                }
                return model;
            })];

        case AuthEnum.SWITCH_ROLE:
            return []
        default:
            return state;
    }
};

export const FilterReducer = (state: FiltersType,
    action: CombinedType, gState: AppInitialStateType
): FiltersType => {
    switch (action.type) {
        case FilterEnum.SET_SUMMARY_VIEW:
            return { ...state, selectedSummaryView: action.payload.name as unknown as ValueSummaryCategoriesTotalData };
        case FilterEnum.SET_ACTUAL:
            return { ...state, selectedActual: action.payload.name };
        case FilterEnum.SET_FORECAST:
            return { ...state, selectedForecast: action.payload.name };
        case FilterEnum.SET_CATEGORY:
            return {
                ...state,
                selectedCategory: action.payload.name,
                selectedActual: state.actualsList.length > 0 ? state.actualsList[0] : '',
                selectedForecast: state.forecastList.length > 0 ? state.forecastList[0] : '',
            };

        case FilterEnum.FETCH_CATEGORIES:
            return {
                ...state,
                error: '',
                categories: action.payload.categoriesList,
                forecastList: action.payload.forecastList,
                actualsList: action.payload.actualsList,
                selectedActual: action.payload.actualsList.length > 0 ? action.payload.actualsList[0] : '',
                selectedForecast: action.payload.forecastList.length > 0 ? action.payload.forecastList[0] : '',
            };
        case FilterEnum.FETCH_FILTERS:
            return {
                ...state,
                forecastList: action.payload.forecastList,
                actualsList: action.payload.actualsList
            };
        case FilterEnum.FETCH_CATEGORIES_LIST_ERROR:
            return {
                ...state,
                error: action.payload.message,
            }

        case AuthEnum.SWITCH_ROLE:
            return {
                ...state,
                selectedCategory: '',
                selectedActual: state.actualsList.length > 0 ? state.actualsList[0] : '',
                selectedForecast: state.forecastList.length > 0 ? state.forecastList[0] : '',
            };
        default:
            return state;
    }
}

export const ForecastReducer = (state: ForecastType[],
    action: CombinedType, gState: AppInitialStateType
): ForecastType[] => {
    switch (action.type) {
        case ForecastActionsEnum.FETCH_FORECAST:
            return [...action.payload.forecasts];
        case AuthEnum.SWITCH_ROLE:
            return []
        default:
            return state;
    }
}

export const PreviousForecastReducer = (state: PreviusForcastData,
    action: CombinedType, gState: AppInitialStateType
): PreviusForcastData => {
    switch (action.type) {
        case ForecastActionsEnum.FETCH_PREVIUS_FORECAST:
            return {
                ...action.payload.previousForecast
            };


        case AuthEnum.SWITCH_ROLE:
            return {
                category: '',
                actuals: [],
                source: '',
                model: '',
                forecast_date: '',
                metric: '',
                avg: [],
                low: [],
                high: []
            }
        default:

            return state;
    }
}

export const ActualReducer = (state: DataPointType[],
    action: CombinedType, gState: AppInitialStateType
): DataPointType[] => {
    switch (action.type) {
        case ActualEnum.FETCH_ACTUALS:
            return [...action.payload.actuals];

        case AuthEnum.SWITCH_ROLE:
            return []

        default:
            return state;
    }
}
export const YoyReducer = (state: YOYChangesType,
    action: CombinedType, gState: AppInitialStateType
): YOYChangesType => {
    switch (action.type) {
        case YoyEnum.FETCH_YOY:
            return {
                ...action.payload.yoy
            };
        case AuthEnum.SWITCH_ROLE:
            return {
                forecasts: [],
                actuals: []
            }
        default:
            return state;
    }
}

export const HeatmapReducer = (state: HeatMapType[],
    action: CombinedType, gState: AppInitialStateType
): HeatMapType[] => {
    switch (action.type) {
        case HeatmapEnum.FETCH_HEATMAP:
            return [
                ...action.payload.heatmap
            ];
        case AuthEnum.SWITCH_ROLE:
            return []
        default:
            return state;
    }
}

export const AuthReducer = (state: AuthType,
    action: CombinedType, gState: AppInitialStateType
): AuthType => {
    switch (action.type) {
        case AuthEnum.FETCH_ROLE:
            return {
                ...state,
                role: action.payload.role,
                view: getRoleViews(action.payload.role as RolesEnum)[0]
            };
        case AuthEnum.SWITCH_ROLE:
            return {
                ...state,
                view: action.payload.view,
            }
        case AuthEnum.FETCH_USER_INFO: {
            return {
                ...state,
                user: action.payload.user,
            }
        }
        default:
            return state;
    }
}

export const SummaryTotalReducer = (
    state: SummaryCategoriesTotalData,
    action: CombinedType,
    gState: AppInitialStateType
): SummaryCategoriesTotalData => {
    switch (action.type) {
        case SummaryEnum.FETCH_SUMMARY:
            return {
                ...state,
                market_summary: action.payload.summary?.market_summary,
                clorox_summary: action.payload.summary?.clorox_summary
            }

        default:
            return state;
    }
}

export const NotificationReducer = (
    state: Notification,
    action: CombinedType,
    gState: AppInitialStateType
): Notification => {
    switch (action.type) {
        case NotificationEnum.SET_WARNING_NOTIFICATION:
            return {
                ...state,
                messageId: uuidv4(),
                type: 'warning',
                message: action.payload.message
            }

        case NotificationEnum.SET_ERROR_NOTIFICATION:
            return {
                ...state,
                messageId: uuidv4(),
                type: 'error',
                message: action.payload.message
            }
        case NotificationEnum.SET_SUCCESS_NOTIFICATION:
            return {
                ...state,
                messageId: uuidv4(),
                type: 'success',
                message: action.payload.message
            }
        case NotificationEnum.SET_INFORMATION_NOTIFICATION:
            return {
                ...state,
                messageId: uuidv4(),
                type: 'info',
                message: action.payload.message
            }

        case NotificationEnum.START_LOADING_FORECAST:
            return {
                ...state,
                isLoadingForecast: true
            }

        case NotificationEnum.STOP_LOADING_FORECAST:
            return {
                ...state,
                isLoadingForecast: false
            }
        case NotificationEnum.STOP_LOADING_SUMMARY:
            return {
                ...state,
                isLoadingSummary: true
            }

        case NotificationEnum.STOP_LOADING_SUMMARY:
            return {
                ...state,
                isLoadingSummary: false
            }
        default:
            return state;
    }
}

export const RecommendedRequestsReducer = (state: RequestPendingModel[],
    action: CombinedType, gState: AppInitialStateType
): RequestPendingModel[] => {
    switch (action.type) {
        case RequestPandingModelActionsEnum.FETCH_RECOMMENDED_REQUESTS:
            return [...action.payload.requests]

        default:
            return state;
    }
}