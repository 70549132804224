import {FC, Suspense} from 'react';
import { JSX } from 'react/jsx-runtime';

// project import
import Loader from './loader';

const Loadable = (Component: FC) => (props: JSX.IntrinsicAttributes) => (
    <Suspense fallback={<Loader />}>
        <Component {...props} />
    </Suspense>
);

export default Loadable;