import {RolesEnum, ViewsEnum} from "../constants/roles.enum";

import React, {FC, lazy, useContext, useMemo, useEffect} from 'react';
import {useIsAuthenticated, } from "@azure/msal-react";

// project import
import Loadable from 'components/loadable';
import {AppContext} from "context";
import ApiService from 'services/api.service';
import Loader from "components/loadable/loader";
import { isAllowedView } from "config/roles.config";
const Comparison = Loadable(lazy(() => import('pages/forecast-comparison')));
const Summary = Loadable(lazy(() => import('pages/forecast-summary')));
const TotalSummary = Loadable(lazy(() => import('pages/total-summary')));
const selectedStrategy = (view: ViewsEnum): FC => {
    const componentByStatus = {
        [ViewsEnum.FORECAST_SUMMARY]: () => <Summary/>,
        [ViewsEnum.FORECAST_COMPARISON]: () => <Comparison/>,
        [ViewsEnum.TOTAL_SUMMARY]: () => <TotalSummary/>
    }

    return componentByStatus[view] ? componentByStatus[view] : () => <Loader />;
}

const Consumer: FC<{ StrategyComponent: FC }> = ({StrategyComponent}) => <StrategyComponent/>

const ConsumerWrapper: FC = () => {
    const {state: {auth: {role, view}}, dispatch} = useContext(AppContext);

    const isAuthenticated = useIsAuthenticated();

    useEffect(() => {
        if(isAuthenticated){
            ApiService.getUserInfo(dispatch);
            ApiService.getUserRole(dispatch)
        }
    }, [isAuthenticated]);


    return useMemo(() => {
        return (<Consumer StrategyComponent={selectedStrategy(isAllowedView(role as RolesEnum, view) ? view : -1)}/>)
    }, [role, view]);
}

export default ConsumerWrapper