import React, {FC} from "react";
import {InteractionStatus} from "@azure/msal-browser";
import {useIsAuthenticated, useMsal} from "@azure/msal-react";
import {loginRequest} from "../config/auth.config";

export const ProtectedRoute: FC<{ children: React.ReactElement }> = ({children}) => {
    const isAuthenticated = useIsAuthenticated();
    const {instance, inProgress} = useMsal();

    if (!isAuthenticated && inProgress === InteractionStatus.None) {
        instance.loginRedirect(loginRequest).catch((e) => {
            console.log(e);
        });

        return <></>;
    }

    // if (!isAuthenticated) {
    //     return <Navigate to="/login"/>;
    // }

    return children;
};