
import { FC, useContext, useState } from 'react';
import {
    Menu,
    MenuItem,
    IconButton,
    Button,
    Box,
    Tooltip
} from "@mui/material";
import NotificationsIcon from '@mui/icons-material/Notifications';
import { RequestPendingModel } from 'context/app-reducers';
import './request-menu.css';
export const RequestsMenu: FC<{ requests: RequestPendingModel[], onApprove: (model: RequestPendingModel)=>void, onDecline: (model: RequestPendingModel)=> void}> = ({ requests, onApprove, onDecline }) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleApprove = (model: RequestPendingModel)=>{
        onApprove(model)
    }

    const handleDecline = (model: RequestPendingModel)=>{
        onDecline(model)
    }

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    return (
        <>
            <IconButton
                onClick={handleClick}
                size="small"
                sx={{ ml: 2 }}
                disabled={!requests.length}
                aria-controls={open ? 'requests-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
            >
                <NotificationsIcon />
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                id="requests-menu"
                open={open}
                onClose={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '&::before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                {
                    requests.map((request) => (
                        <MenuItem >
                            <Box className={'recommended-request-wrapper'}>
                                <Tooltip title={request.created_by}>
                                    <Box component='span'>{request.created_by}</Box>
                                </Tooltip>
                                <Tooltip title={request.model}>
                                    <Box component='span'>{request.model}</Box>
                                </Tooltip>
                                <Tooltip title={request.change_reason}>
                                    <Box component='span' className='recommended-request-reason'>{request.change_reason}</Box>
                                </Tooltip>
                                <Button variant="outlined" color={'info'} onClick={()=>handleDecline(request)}>Decline</Button>
                                <Button variant="contained" onClick={()=>handleApprove(request)}>Approve</Button>
                            </Box>
                        </MenuItem>
                    ))
                }

            </Menu>
        </>
    )
}