import React, { createContext, Dispatch, PropsWithChildren, useReducer } from 'react';
import {
    ActualReducer,
    ActualsActions,
    AuthActions,
    AuthReducer,
    AuthType,
    DataPointType,
    FilterActions,
    FilterReducer,
    FiltersType,
    ForecastActions,
    ForecastReducer,
    ForecastType,
    HeatmapActions,
    HeatmapReducer,
    HeatMapType,
    ModelActions,
    ModelReducer,
    ModelsType,
    Notification,
    NotificationActions,
    NotificationReducer,
    SummaryActions,
    SummaryCategoriesTotalData,
    SummaryTotalReducer,
    PreviousForecastReducer,
    RecommendedRequestsReducer,
    YoyActions,
    YOYChangesType,
    YoyReducer,
    PreviosForecastActions,
    PreviusForcastData,
    RequestPendingModel
} from './app-reducers';
import { SummaryEnum } from 'constants/actions.enum';

export type AppInitialStateType = {
    filters: FiltersType,
    auth: AuthType,
    forecasts: ForecastType[],
    previousForecast: PreviusForcastData,
    actuals: DataPointType[],
    yoy_changes: YOYChangesType,
    models: ModelsType[],
    heatmap: HeatMapType[],
    summary: SummaryCategoriesTotalData,
    notification: Notification,
    recommendedRequests: RequestPendingModel[]
}


const initialState: AppInitialStateType = {
    filters: {
        categories: [],
        selectedCategory: '',
        actualsList: [],
        forecastList: [],
        selectedActual: '',
        selectedForecast: '',
        selectedSummaryView: SummaryEnum.CLOROX,
        error: ''
    },
    auth: {
        role: '',
        view: -1,
        user: {
            businessPhones: '',
            displayName: '',
            givenName: '',
            id: '',
            jobTitle: '',
            mail: '',
            mobilePhone: '',
            officeLocation: '',
            preferredLanguage: '',
            surname: '',
            userPrincipalName: '',
        }
    },
    actuals: [],
    yoy_changes: {
        forecasts: [],
        actuals: []
    },
    models: [],
    forecasts: [],
    previousForecast: {
        category: '',
        actuals: [],
        source: '',
        model: '',
        forecast_date: '',
        metric: '',
        avg: [],
        low: [],
        high: []
    },
    heatmap: [],
    summary: {
        "market_summary": [],
        "clorox_summary": [],
    },
    recommendedRequests: [],
    notification: { message: '', type: '', messageId: '', isLoadingForecast: false, isLoadingSummary: false }
};

export const AppContext = createContext<{
    state: AppInitialStateType;
    dispatch: Dispatch<FilterActions | ModelActions | ForecastActions | ActualsActions | YoyActions | HeatmapActions | AuthActions | SummaryActions | NotificationActions | PreviosForecastActions>
}>({
    state: initialState,
    dispatch: () => null
});


const mainReducer = (
    gState: AppInitialStateType,
    action: ModelActions | FilterActions | ForecastActions | ActualsActions | YoyActions | HeatmapActions | AuthActions | SummaryActions | NotificationActions | PreviosForecastActions
): AppInitialStateType => {
    const {
        models,
        filters,
        forecasts,
        actuals,
        yoy_changes,
        heatmap,
        auth,
        summary,
        notification,
        recommendedRequests,
        previousForecast
    } = gState
    return {
        models: ModelReducer(models, action, gState),
        forecasts: ForecastReducer(forecasts, action, gState),
        filters: FilterReducer(filters, action, gState),
        yoy_changes: YoyReducer(yoy_changes, action, gState),
        actuals: ActualReducer(actuals, action, gState),
        heatmap: HeatmapReducer(heatmap, action, gState),
        auth: AuthReducer(auth, action, gState),
        summary: SummaryTotalReducer(summary, action, gState),
        notification: NotificationReducer(notification, action, gState),
        recommendedRequests: RecommendedRequestsReducer(recommendedRequests, action, gState),
        previousForecast: PreviousForecastReducer(previousForecast, action, gState)
    }
};

export const AppProvider = (props: PropsWithChildren) => {
    const [state, dispatch] = useReducer(mainReducer, initialState);

    return (
        <AppContext.Provider value={{ state, dispatch }}>
            {props.children}
        </AppContext.Provider>
    );
};

