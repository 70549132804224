import {EventType, LogLevel, PublicClientApplication} from "@azure/msal-browser";

export const msalConfig = {
    auth: {
        clientId: process.env.REACT_APP_AUTH_CONFIG_CLIENT_ID || '',
        authority:
            "https://login.microsoftonline.com/" + process.env.REACT_APP_AUTH_CONFIG_TENANT_ID || '',
        redirectUri: process.env.REACT_APP_AUTH_CONFIG_REDIRECT_URL || '/',
        postLogoutRedirectUri: process.env.REACT_APP_AUTH_CONFIG_REDIRECT_URL || '/',
    },
    cache: {
        cacheLocation: "sessionStorage", // This configures where your cache will be stored
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
    system: {
        loggerOptions: {
            loggerCallback: (level: any, message: any, containsPii: any) => {
                if (containsPii) {
                    return;
                }
                switch (level) {
                    case LogLevel.Error:
                        console.error(message);
                        return;
                    case LogLevel.Info:
                        console.info(message);
                        return;
                    case LogLevel.Verbose:
                        console.debug(message);
                        return;
                    case LogLevel.Warning:
                        console.warn(message);
                        return;
                    default:
                        return;
                }
            },
        },
    },
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
    scopes: ["openid", "profile", "user.read"]
};
// 'Team.ReadBasic.All, TeamSettings.Read.All, TeamSettings.ReadWrite.All, User.Read.All, Directory.Read.All, User.ReadWrite.All, Directory.ReadWrite.All'. Scopes on the request 'openid, profile, User.Read, email'",

const msalInstance = new PublicClientApplication(msalConfig);
msalInstance.initialize();
msalInstance.enableAccountStorageEvents();


// Default to using the first account if no account is active on page load
if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
    // Account selection logic is app dependent. Adjust as needed for different use cases.
    // @ts-ignore
    msalInstance.setActiveAccount(msalInstance.getActiveAccount()[0]);
}

// Listen for sign-in event and set active account
msalInstance.addEventCallback((event) => {
    // @ts-ignore
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
        // @ts-ignore
        const account = event.payload.account;
        msalInstance.setActiveAccount(account);
    }
});

export const graphConfig = {
    graphMeEndpoint: "https://graph.microsoft.com/v1.0/me",
    graphMeTeamEndpoint: "https://graph.microsoft.com/v1.0/groups/77b63a12-8621-477d-b068-3f3e8535b666",
    graphMeTeamGroupEndpoint: "https://graph.microsoft.com/v1.0/groups",

};
export default msalInstance;