import {FC, PropsWithChildren, useContext, useState} from 'react';
import {Outlet} from 'react-router-dom';
import {
    AppBar,
    Avatar,
    Badge,
    Box,
    Divider,
    IconButton,
    ListItemIcon,
    Menu,
    MenuItem,
    Toolbar,
    Tooltip
} from "@mui/material";
import {v4 as uuidv4} from "uuid";
import AccountCircle from '@mui/icons-material/AccountCircle';
import NotificationsIcon from '@mui/icons-material/Notifications';
import {AppContext} from "context";
import {useIsAuthenticated, useMsal} from "@azure/msal-react";
import {AuthEnum} from "constants/actions.enum";
import CheckIcon from '@mui/icons-material/Check';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import {RolesEnum, ViewsEnum} from "constants/roles.enum";
import {brandColorHex} from "theme";
import IsRecommendedRequestsTray from 'components/isrecommended-requests-menu';
import { getRoleViews, isAllowedView } from 'config/roles.config';

interface IDefaultPageLayout extends PropsWithChildren {
}


// @ts-ignore
export const DefaultPageLayout: FC<IDefaultPageLayout> = () => {
    const {state: {filters: {selectedCategory}, auth: {role, view, user}}, dispatch} = useContext(AppContext);
    const {instance} = useMsal();
    const isAuthenticated = useIsAuthenticated();
    const userRolesMenuItem = [{
        label: 'Analyst',
        value: ViewsEnum.FORECAST_COMPARISON
    }, {
        label: 'Manager',
        value: ViewsEnum.FORECAST_SUMMARY
    }, {
        label: 'Business Partner',
        value: ViewsEnum.TOTAL_SUMMARY
    }];

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] =
        useState<null | HTMLElement>(null);

    const isMenuOpen = Boolean(anchorEl);
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
    const isAllowedTheRequests = role === RolesEnum.SUPERUSER;
    const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMobileMenuClose = () => {
        setMobileMoreAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleLogout = () => {
        setAnchorEl(null);

        instance.logoutRedirect({
            postLogoutRedirectUri: "/login",
        });
    };

    const setViewRole = (viewId: ViewsEnum) => {
        dispatch({type: AuthEnum.SWITCH_ROLE, payload: {view: viewId}});
    }
    const handleMenuClose = () => {
        setAnchorEl(null);
        handleMobileMenuClose();
    };

    const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setMobileMoreAnchorEl(event.currentTarget);
    };

    const menuId = 'primary-search-account-menu';


    return (
        <>
            <Box>
                <AppBar position="static" sx={{color: '#FFF !important', borderRadius: 0}}>
                    <Toolbar>
                        {/*<IconButton*/}
                        {/*    size="large"*/}
                        {/*    edge="start"*/}
                        {/*    color="inherit"*/}
                        {/*    aria-label="open drawer"*/}
                        {/*    sx={{mr: 2}}*/}
                        {/*>*/}
                        {/*    <MenuIcon/>*/}
                        {/*</IconButton>*/}
                        <Box sx={{flexGrow: 1}}/>
                        <Box sx={{display: {xs: 'none', md: 'flex', color: '#fff'}}}>
                        {isAllowedTheRequests && <IsRecommendedRequestsTray />}
                            <IconButton
                                size="large"
                                edge="end"
                                aria-label="account of current user"
                                aria-controls={menuId}
                                aria-haspopup="true"
                                onClick={handleProfileMenuOpen}
                                sx={{color: '#fff'}}
                            >
                                {
                                    <Tooltip title="Account settings">
                                        {isAuthenticated ? <IconButton
                                                onClick={handleClick}
                                                size="small"
                                                sx={{ml: 2}}
                                                aria-controls={open ? 'account-menu' : undefined}
                                                aria-haspopup="true"
                                                aria-expanded={open ? 'true' : undefined}
                                            >
                                                <Avatar sx={{width: 32, height: 32, fontSize: '16px'}}>{user?.givenName?.substring(0, 1)}{user?.surname?.substring(0, 1)}</Avatar>
                                            </IconButton> :
                                            <AccountCircle/>
                                        }
                                    </Tooltip>
                                }
                            </IconButton>
                        </Box>
                        <Menu
                            anchorEl={anchorEl}
                            id="account-menu"
                            open={open}
                            onClose={handleClose}
                            PaperProps={{
                                elevation: 0,
                                sx: {
                                    overflow: 'visible',
                                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                    mt: 1.5,
                                    '& .MuiAvatar-root': {
                                        width: 32,
                                        height: 32,
                                        ml: -0.5,
                                        mr: 1,
                                    },
                                    '&::before': {
                                        content: '""',
                                        display: 'block',
                                        position: 'absolute',
                                        top: 0,
                                        right: 14,
                                        width: 10,
                                        height: 10,
                                        bgcolor: 'background.paper',
                                        transform: 'translateY(-50%) rotate(45deg)',
                                        zIndex: 0,
                                    },
                                },
                            }}
                            transformOrigin={{horizontal: 'right', vertical: 'top'}}
                            anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
                        >
                            <MenuItem onClick={handleClose}>
                                {/*<Avatar/> Profile*/}
                                {user.displayName}
                            </MenuItem>
                            <Divider/>
                            {getRoleViews(role as RolesEnum).length > 1 && <MenuItem>
                                <ListItemIcon>
                                    <SwapHorizIcon fontSize="small"/>
                                </ListItemIcon>
                                Switch View To:
                            </MenuItem>}
                            {
                                userRolesMenuItem.map(({label, value}) => (
                                    <>
                                        {isAllowedView(role as RolesEnum, value) && <MenuItem
                                            key={`skeleton-${uuidv4()}`}
                                            sx={view === value ? {
                                                    color: brandColorHex,
                                                    fontWeight: 700
                                                } :
                                                {color: 'inherit', fontWeight: 'inherit'}}
                                            onClick={() => {
                                                setViewRole(value)
                                            }}>
                                            {
                                                <ListItemIcon
                                                    sx={{visibility: view !== value ? 'hidden' : 'initial'}}>
                                                    <CheckIcon sx={{color: view === value ? brandColorHex : 'inherit'}}
                                                            fontSize="small"/>
                                                </ListItemIcon>
                                            }
                                            {label}
                                        </MenuItem>
                                    }
                                    </>
                                ))
                            }
                            {/* <Divider/>
                            <MenuItem onClick={handleClose}>
                                <ListItemIcon>
                                    <PersonOutlineOutlinedIcon fontSize="small"/>
                                </ListItemIcon>
                                Profile
                            </MenuItem>
                            <MenuItem onClick={handleClose}>
                                <ListItemIcon>
                                    <SettingsOutlinedIcon fontSize="small"/>
                                </ListItemIcon>
                                Settings
                            </MenuItem> */}
                            <Divider/>
                            <MenuItem onClick={handleLogout}>
                                <ListItemIcon>
                                    <LogoutOutlinedIcon fontSize="small"/>
                                </ListItemIcon>
                                Logout
                            </MenuItem>
                        </Menu>
                    </Toolbar>
                </AppBar>
            </Box>
            <Box sx={{display: 'flex', width: '100%'}}>
                <Box component="main" sx={{width: 'calc(100% - 260px)', flexGrow: 1, p: {xs: 2, sm: 3}, paddingTop: 0}}>
                    <Outlet/>
                </Box>
            </Box>
        </>
    );
}