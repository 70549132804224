import axios, { AxiosRequestConfig } from "axios";
import { graphConfig, loginRequest } from "../config/auth.config";
import msalInstance from 'config/auth.config';
import { getEncryptStorage } from "config/storage.config";

const options = {};

class NetworkService {
    static instance: NetworkService;

    static getInstance() {
        if (!NetworkService.instance) {
            NetworkService.instance = new NetworkService();
        }
        return NetworkService.instance;
    }

    static prepareRequest(url: string, config: any, headerConfig = {}) {
        const token = localStorage.getItem("token");
        const user = token && JSON.parse(token);
        const authorizationToken = config.useAccessToken ? user.accessToken : user.idToken;

        const headers = {
            Authorization: `Bearer ${authorizationToken}`
        };

        const axiosConfig = {
            url,
            headers: { ...headers, ...headerConfig },
            ...config,
        };
        return axiosConfig;
    }

    async get(url: string, params = null, config = {}) {
        await this.refreshToken();
        const axiosConfig = NetworkService.prepareRequest(url, config);
        axiosConfig.method = "GET";
        if (params !== null) {
            axiosConfig.params = params;
        }
        return this.getResponse(axiosConfig);
    }

    async post(url: string, data: any, config = options, headerConfig = {}) {
        await this.refreshToken();
        const axiosConfig = NetworkService.prepareRequest(url, config, headerConfig);
        axiosConfig.method = "POST";
        if (config) {
            axiosConfig.params = config;
        }
        axiosConfig.data = data;
        return this.getResponse(axiosConfig);
    }

    async put(url: string, data: any, config = {}) {
        await this.refreshToken();
        const axiosConfig = NetworkService.prepareRequest(url, config);
        axiosConfig.method = "PUT";
        if (config) {
            axiosConfig.params = config;
        }
        axiosConfig.data = data;
        return this.getResponse(axiosConfig);
    }

    async delete(url: string, params = null, config = {}) {
        await this.refreshToken();
        const axiosConfig = NetworkService.prepareRequest(url, config);
        axiosConfig.method = "DELETE";
        if (params !== null) {
            axiosConfig.params = params;
        }
        return this.getResponse(axiosConfig);
    }

    async getResponse(config: AxiosRequestConfig<any>) {
        try {
            const response = await axios(config);
            return response.data;
        } catch (axiosError: any) {
            // @ts-ignore
            return await Promise.reject(axiosError.message);
        }
    }

    async refreshToken() {
        const date = new Date();
        const token = localStorage.getItem("token")
        const user = token && JSON.parse(token);
        const expiryTime = user?.expiresOn;

        if (!expiryTime) {
            await this.acquireRefreshAccessToken();
        }
        if (expiryTime && parseInt(expiryTime) < date.getTime() / 1000) {
            await this.acquireRefreshAccessToken();
        }
    }

    async acquireRefreshAccessToken() {
        try {
            const response = await msalInstance.acquireTokenSilent({
                ...loginRequest,
            });

            if (response && response.idToken && response.idTokenClaims) {
                localStorage.setItem('token', JSON.stringify({
                    idToken: response.idToken,
                    // @ts-ignore
                    expiresOn: response?.idTokenClaims?.exp,
                    accessToken: response.accessToken
                }))
            }
            
        } catch (e) {
            try {
                const popupResponse = await msalInstance.acquireTokenPopup({ ...loginRequest })
                if (popupResponse && popupResponse.idToken && popupResponse.idTokenClaims) {
                    localStorage.setItem('token', JSON.stringify({
                        idToken: popupResponse.idToken,
                        // @ts-ignore
                        expiresOn: popupResponse?.idTokenClaims?.exp,
                        accessToken: popupResponse.accessToken
                    }))
                }
            } catch (err) {
                console.error(err)
            };
            console.error(e)
        }
    }

    
}

export default NetworkService.getInstance();