import {createTheme, styled} from '@mui/material/styles';
import Switch from '@mui/material/Switch';
import TableCell, {tableCellClasses} from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";


export const brandColorHex = '#196EEF';
export const blackSpecial = '#344054';
export const yellowSpecial = '#FFA800'
export const magentaSpecial = '#D500F9'
export const redSpecial = '#8B0000'
export const lightRedSpecial = '#EF1919'
export const greenSpecial = '#49BDB6'
export const naturalGreenSpecial = '#37AD00'
export const greySpecial = '#86909C'

export const lightGreySpecial = '#FBFBFB'
export const greyDarkSpecial = '#76777A'
export const tableHeadCell = '';

export const negativeTextRed = '#BF0000';


// A custom theme for this app
const theme = createTheme({
    typography: {
        "fontFamily": `"Poppins", sans-serif`,
        "fontSize": 16,
        "fontWeightLight": 400,
        "fontWeightRegular": 500,
        "fontWeightMedium": 600,
        body2: {
            fontWeight: 400,
            color: '#344054'

        }
    },
    palette: {
        primary: {
            main: brandColorHex,
        },
        secondary: {
            main: greySpecial,
        },
        error: {
            main: negativeTextRed,
        },
        info: {
            main: greyDarkSpecial
        }
    },
    components: {
        MuiDialog: {
            styleOverrides: {
                root: {
                    backgroundColor: '#D9EDFFBF'
                },
            }
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius: '8px',
                    textTransform: 'capitalize'
                }
            }
        },
        MuiCard: {
            styleOverrides: {
                root: {
                    borderRadius: '8px',
                    boxShadow: '0px 2px 20px 0px rgba(9, 6, 141, 0.08), 0px 1px 1px 0px rgba(9, 6, 141, 0.08), 0px 1px 3px 0px rgba(9, 6, 141, 0.08);',
                    borderColor: 'transparent'
                },

            }
        },
        MuiBackdrop: {
            styleOverrides: {
                root: {
                    backgroundColor: 'transparent'

                }
            }
        },
        MuiToggleButton: {
            styleOverrides: {
                root: {
                    borderRadius: '8px',
                    backgroundColor: 'transparent',
                    '&.Mui-selected': {
                        color: blackSpecial,
                        borderColor: brandColorHex,
                        backgroundColor: 'transparent',

                    }
                }
            }

        },
        MuiCheckbox: {
            styleOverrides: {
                root: {
                    color: brandColorHex
                }
            }
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    borderRadius: '8px',
                    // border: '1px solid #D0D5DD'
                }
            }
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    borderRadius: '8px',
                    boxShadow: '0px 2px 20px 0px rgba(9, 6, 141, 0.08), 0px 1px 1px 0px rgba(9, 6, 141, 0.08), 0px 1px 3px 0px rgba(9, 6, 141, 0.08);',
                    borderColor: 'transparent'
                }
            }
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    borderRadius: '8px',
                }
            }
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    marginTop: '8px',
                    marginBottom: '8px',
                }
            }
        },
        MuiIconButton: {
            styleOverrides: {
                root: {
                    color: blackSpecial
                }
            }
        }
    }
});


export const AntSwitch = styled(Switch)(({theme}) => ({
    width: 44,
    height: 24,
    padding: 0,
    display: 'flex',
    '&:active': {
        '& .MuiSwitch-thumb': {
            width: 23,
        },
        '& .MuiSwitch-switchBase.Mui-checked': {
            transform: 'translateX(10px)',
        },
    },
    '& .MuiSwitch-switchBase': {
        padding: 2,
        '&.Mui-checked': {
            transform: 'translateX(20px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                opacity: 1,
                backgroundColor: brandColorHex,
            },
        },
    },
    '& .MuiSwitch-thumb': {
        boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
        width: 20,
        height: 20,
        borderRadius: 16,
        transition: theme.transitions.create(['width'], {
            duration: 200,
        }),
    },
    '& .MuiSwitch-track': {
        borderRadius: 24 / 2,
        opacity: 1,
        backgroundColor:
            theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
        boxSizing: 'border-box',
    },
}));

export const StickyStyledTableCell = styled(TableCell)(({theme}) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#CBE7FF",
        color: "#6B7280",
        position: "sticky",
        left: 0,
        border: 0,
        fontSize: 16,
        fontWeight: 500,
        width: '100px',
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 16,
        fontWeight: 500,
        color: "#484848",
        backgroundColor: 'inherit',
        position: "sticky",
        border: 0,
        left: 0,
        zIndex: 1,
        width: '100px',
    },
    [`&.${tableCellClasses.head}:after`]: {
        content: '""',
        zIndex: 5,
        position: "absolute",
        right: 0,
        width: '100%',
        top: 0,
        height: '100%',
        boxShadow: "-14px 20px 19px 8px rgba(135, 136, 136, 0.25)",
    }, [`&.${tableCellClasses.body}:after`]: {
        content: '""',
        zIndex: 5,
        position: "absolute",
        right: 0,
        border: 0,
        width: '100%',
        top: 0,
        height: '100%',
        boxShadow: "-14px 20px 19px 8px rgba(135, 136, 136, 0.25)",
    },
}));

export const StyledTableCell = styled(TableCell)(({theme}) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#d9edff",
        color: "#6B7280",
        border: 0,
        fontWeight: 500,
        fontSize: 16,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 16,
        border: 0,
        fontWeight: 500,
        color: "#484848",
    },
}));

export const StyledDarkTableCell = styled(TableCell)(({theme}) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: brandColorHex,
        color: "#fff",
        border: '1px',
        borderColor: 'transparent',
        fontWeight: 700,
        fontSize: 16,
    },
    [`&.${tableCellClasses.head}:first-child`]: {
        borderRightColor: '#fff'
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 16,
        border: '1px',
        backgroundColor: '#B4DCFF',
        borderColor: 'transparent',
        fontWeight: 500,
        color: "#484848",
    },
}));

export const StyledDefaultTableRow = styled(TableRow)(({theme}) => ({
    textAlign: 'center',
    '&:nth-of-type(odd)': {
        backgroundColor: lightGreySpecial,
    },
    '&:nth-of-type(even)': {
        backgroundColor: '#fff',
    },
}));
export default theme;