// export enum RolesEnum {
//     BUSINESS_PARTNER = 0,
//     BUSINESS_ANALYST = 1,
//     BUSINESS_PARTNER_HIGH = 2,
// }


export enum RolesEnum {
    SUPERUSER = "Superuser",
    ANALYST = "Analyst",
    MANAGER = "Manager",
    BUSINESS_PARTNER = "Business Partner"
}


export enum ViewsEnum {
    FORECAST_COMPARISON = 0,
    FORECAST_SUMMARY = 1,
    TOTAL_SUMMARY = 2
}
