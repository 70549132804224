import router from 'routes';
import {RouterProvider} from 'react-router-dom';
import "@fontsource/poppins/300.css";
import "@fontsource/poppins/400.css";
import "@fontsource/poppins/500.css";
import "@fontsource/poppins/600.css";
import "@fontsource/poppins/700.css";

import './App.css';
import Notification, {useNotification} from "./components/notification";

function App() {
    const {message, type, messageId} = useNotification();

    return (
        <div className="App">
            <RouterProvider router={router}/>
            <Notification message={message} type={type} messageId={messageId}/>
        </div>
    );
}

export default App;
