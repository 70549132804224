import {FC, useContext, useEffect} from 'react';
import {useIsAuthenticated} from "@azure/msal-react";

import {AppContext} from "context";

import CheckIcon from '@mui/icons-material/Check';
import NotificationsIcon from '@mui/icons-material/Notifications';
import {
    Badge,
    IconButton,
} from "@mui/material";
import { RequestsMenu } from './requests-menu';
import ApiService from 'services/api.service';
import { RequestPendingModel } from 'context/app-reducers';

export const IsRecommendedRequestsTray: FC<{}> = ()=> {
    const {state: {filters: {selectedCategory}, auth: {role}, recommendedRequests}, dispatch} = useContext(AppContext);
    const isAuthenticated = useIsAuthenticated();
    

    const onApprove = (model: RequestPendingModel)=>{
        ApiService.updateRequestsApprove(dispatch, model.model, model.source, model.category, model.forecast_date);
    }

    const onDecline = (model: RequestPendingModel)=>{
        ApiService.updateRequestsDecline(dispatch, model.model, model.source, model.category, model.forecast_date);
    }

    useEffect(()=>{
        ApiService.getPendingRequests(dispatch);
    }, [isAuthenticated])
    
    return(
         <>
        {isAuthenticated && <IconButton
            size="large"
            aria-label={`show ${recommendedRequests.length} new requests`}
            sx={{color: '#fff'}}
        >
            <Badge badgeContent={recommendedRequests.length} color="error">
                <RequestsMenu requests={recommendedRequests} onApprove={onApprove} onDecline={onDecline}/>
            </Badge>
        </IconButton>
    }
    </>)
}