import {FC, useEffect, useState} from "react";

import {Alert, IconButton, Snackbar} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close'
import {AlertColor} from "@mui/material/Alert/Alert";

export const Notification: FC<{ message: string, type: string, messageId: string }> = ({message, messageId, type}) => {
    const [notiOpen, setNotiOpen] = useState(true)

    useEffect(() => {
        setNotiOpen(true)
        console.log(`Type:${type} - with message "${message}"`)
    }, [messageId]);


    if (!message) {
        return null
    }

    return (
        <Snackbar
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'center'
            }}
            key={messageId}
            open={notiOpen}
            autoHideDuration={5000}
            onClose={() => setNotiOpen(false)}
            message={message}
            action={
                <>
                    <IconButton
                        size="small"
                        aria-label="close"
                        color="inherit"
                        onClick={() => setNotiOpen(false)}
                    >
                        <CloseIcon fontSize="small" color={'inherit'}/>
                    </IconButton>
                </>
            }
        ><Alert
            onClose={() => setNotiOpen(false)}
            severity={type as AlertColor}
            variant="standard"
            sx={{width: '100%'}}>
            {message}
        </Alert>
        </Snackbar>
    )
};