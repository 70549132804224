// material-ui
import {FC} from 'react';

import {styled} from '@mui/material/styles';
import LinearProgress from '@mui/material/LinearProgress';

// loader style
const LoaderWrapper = styled('div')(({theme}) => ({
    position: 'fixed',
    top: '72px',
    left: 0,
    zIndex: 2001,
    width: '100%',
    '& > * + *': {
        marginTop: theme.spacing(2)
    }
}));

const Loader: FC<{}> = () => (
    <LoaderWrapper>
        <LinearProgress sx={{height: '8px'}} color="primary"/>
    </LoaderWrapper>
);

export default Loader;