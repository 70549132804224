import exp from "constants";
import { RolesEnum, ViewsEnum } from "constants/roles.enum";

export const rolesMap = {
    [RolesEnum.SUPERUSER] : [ViewsEnum.FORECAST_COMPARISON, ViewsEnum.FORECAST_SUMMARY, ViewsEnum.TOTAL_SUMMARY],
    [RolesEnum.ANALYST]:  [ViewsEnum.FORECAST_COMPARISON, ViewsEnum.FORECAST_SUMMARY, ViewsEnum.TOTAL_SUMMARY],
    [RolesEnum.MANAGER]: [ViewsEnum.FORECAST_SUMMARY, ViewsEnum.TOTAL_SUMMARY],
    [RolesEnum.BUSINESS_PARTNER]: [ViewsEnum.TOTAL_SUMMARY]
}

export const isAllowedView = (role:RolesEnum, view: ViewsEnum) => {
    return !!role && rolesMap[role].includes(view)
}

export const getRoleViews = (role:RolesEnum) =>{
    return rolesMap[role] ? rolesMap[role] : []
}