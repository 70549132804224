import {useContext, useEffect} from "react";
import {AppContext} from "context";

export const useNotification = () => {
    const {
        state: {notification: {message, messageId, type}}
    } = useContext(AppContext);

    useEffect(() => {
    }, [message, messageId, type]);
    return {message, messageId, type}
};
