export enum FilterEnum {
    SET_CATEGORY = 'SET_CATEGORY',
    SET_ACTUAL = 'SET_ACTUAL',
    SET_FORECAST = 'SET_FORECAST',
    SET_SUMMARY_VIEW = 'SET_SUMMARY_VIEW',
    FETCH_CATEGORIES = 'FETCH_CATEGORIES',
    FETCH_FILTERS = 'FETCH_FILTERS',
    FETCH_CATEGORIES_LIST_ERROR = 'FETCH_CATEGORIES_LIST_ERROR'
}

export enum ModelEnum {
    LOAD_MODELS = "LOAD_MODELS",
    FETCH_MODELS = "FETCH_MODELS",
    FETCH_MODELS_ERROR = "FETCH_MODELS_ERROR",
    SET_MARKED_MODEL = "SET_MARKED_MODEL",
    SET_HIGH_LOW = "SET_HIGH_LOW"
}

export enum ForecastActionsEnum {
    FETCH_FORECAST = 'FETCH_FORECAST',
    FETCH_PREVIUS_FORECAST = 'FETCH_PREVIUS_FORECAST'
}

export enum RequestPandingModelActionsEnum {
    FETCH_RECOMMENDED_REQUESTS = 'FETCH_RECOMMENDED_REQUESTS',
    APPROVE_RECOMMENDED_REQUEST = 'APPROVE_RECOMMENDED_REQUEST',
    DECLINE_RECOMMENDED_REQUEST =  'DECLINE_RECOMMENDED_REQUEST'
}

export enum ActualEnum {
    FETCH_ACTUALS = 'FETCH_ACTUALS'
}

export enum YoyEnum {
    FETCH_YOY = 'FETCH_YOY'
}

export enum HeatmapEnum {
    FETCH_HEATMAP = 'FETCH_HEATMAP'
}

export enum AuthEnum {
    SET_ROLE = 'SET_ROLE',
    SWITCH_ROLE = 'SWITCH_ROLE',
    FETCH_ROLE = 'FETCH_ROLE',
    FETCH_USER_INFO = 'FETCH_USER_INFO'
}

export enum SummaryEnum {
    FETCH_SUMMARY = 'FETCH_SUMMARY',
    MARKET = 'market_summary',
    CLOROX = 'clorox_summary'
}

export enum NotificationEnum {
    SET_ERROR_NOTIFICATION = 'SET_ERROR_NOTIFICATION',
    SET_WARNING_NOTIFICATION = 'SET_WARNING_NOTIFICATION',
    SET_SUCCESS_NOTIFICATION = 'SET_SUCCESS_NOTIFICATION',
    SET_INFORMATION_NOTIFICATION = 'SET_INFORMATION_NOTIFICATION',
    START_LOADING_FORECAST = 'START_LOADING_FORECAST',
    STOP_LOADING_FORECAST = 'STOP_LOADING_FORECAST',
    START_LOADING_SUMMARY = 'START_LOADING_SUMMARY',
    STOP_LOADING_SUMMARY = 'STOP_LOADING_SUMMARY'
}